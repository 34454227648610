.mat-dialog-container {
  h2 {
    margin-top: 24px;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 1em;
    padding-left: 9px;
    margin-bottom: 0px;
  }
  p {
    padding-left: 24px;
    padding-right: 18px;
    line-height: initial;
  }
}

.mat-dialog-content {
  max-height: 80vh !important;
}
