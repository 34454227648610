/* Mat Form Field */
@use '@angular/material' as mat;

@include mat.form-field-color($explorer-dark-theme);

.mat-focused {
  *:not(textarea) {
    color: mat.get-color-from-palette($explorer-theme-accent, A100) !important;
    caret-color: mat.get-color-from-palette($explorer-theme-accent, A100) !important;
    div.mat-form-field-underline span {
      background-color: mat.get-color-from-palette($explorer-theme-accent, A100) !important;
    } 
  }

  textarea {
    color: white;
    caret-color: white;
    div.mat-form-field-underline span {
      background-color: mat.get-color-from-palette($explorer-theme-accent, A100) !important;
    }
  }
}

.mat-form-field.mat-form-field-appearance-fill,
.mat-form-field.mat-form-field-appearance-fill.mat-focused {
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-flex {
    background-color: mat.get-color-from-palette($explorer-primary-palette, 400);
    border-radius: 25px !important;
    padding-inline: 22px;
  }
  .mat-input-element {
    caret-color: mat.get-color-from-palette($explorer-theme-accent, A100);
  }
  .mat-form-field-infix {
    border: 0 !important;
  }
  input {
    font-size: 1.2em;
  }
  .mat-form-field-label {
    color: mat.get-color-from-palette($explorer-theme-primary, 'A400-contrast');
  }
}
.mat-form-field.mat-form-field-appearance-fill.mat-focused {
  .mat-form-field-label {
    color: mat.get-color-from-palette($explorer-theme-primary, 'A400-contrast');
  }
}

.mat-form-field-type-mat-input {
  .mat-form-field-flex {
  }
}


.mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    background-color: transparent;
    border-bottom: 1px;
  }
  .mat-form-field-infix {
    border: 1px white !important;
  }
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid white;
    border-radius: 0px;
  }
  input {
    font-size: 1.2em;
  }
}

mat-select {
  font-size: 1.5em;
}
mat-select.regular {
  font-size: 1.2em;
}



div.search-input {
  mat-form-field {
   width: 100%
  }
  div.mat-form-field-wrapper {
    padding-bottom: 0;
  }
}


.mat-paginator-page-size-select {
  width: 70%;
  .mat-form-field-flex {
    padding-inline: 10px ;
  }
}

.mat-select-panel {
  background:  mat.get-color-from-palette($explorer-primary-palette, 500);
  .mat-option.mat-selected {
    background:  mat.get-color-from-palette($explorer-primary-palette, 400) !important;
    color:  mat.get-color-from-palette($explorer-theme-primary, '500-contrast') !important;
  }
}

.mat-paginator-page-size-select {
  width: 70px !important;
}