@use '@angular/material' as mat;
@import 'palettes';

@import "fonts.scss";


$explorer-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $headline: mat.define-typography-level(
    $font-size: 1.2em,
    $font-weight: 700
  ),
  $body-1: mat.define-typography-level(
    $font-size: 1.0em,
    $font-weight : 400,
  ),
  $title:  mat.define-typography-level(
    $font-size: 1.2em,
    $font-weight: 700
  ),
  $subheading-1:  mat.define-typography-level(
    $font-size: 1.0em,
    $font-weight: 500
  ),
);

@include mat.core($explorer-typography);

$explorer-theme-primary: mat.define-palette($explorer-primary-palette);
$explorer-theme-accent: mat.define-palette($explorer-secondary-palette, 900, A200, A100);
$explorer-theme-warn: mat.define-palette(mat.$red-palette);


$explorer-dark-theme:  mat.define-dark-theme((
  color: (
    primary: $explorer-theme-primary,
    accent: $explorer-theme-accent,
    warn: $explorer-theme-warn
  ),
  typography: $explorer-typography,
  density: 0,
));

$explorer-light-theme:  mat.define-light-theme((
  color: (
    primary: $explorer-theme-primary,
    accent: $explorer-theme-accent,
    warn: $explorer-theme-warn
  ),
  typography: $explorer-typography,
  density: 0,
));


@include mat.all-component-themes($explorer-dark-theme);
@include mat.input-color($explorer-dark-theme);
@include mat.button-color($explorer-dark-theme);


html {
  min-width: 600px;
}

body {
  background-color: mat.get-color-from-palette($explorer-theme-primary, 500);
  color: mat.get-color-from-palette($explorer-theme-accent, A100);
  -webkit-text-size-adjust: none;
  font-size: 1em;
}

.mat-typography h2 {
  margin: 0 0 5px !important;
}


.text-danger {
  color: mat.get-color-from-palette($explorer-secondary-palette, 400);
}

.strong {
  display: inline-block;
  font-weight: 600;
  padding-right: 5px;
  white-space: nowrap
}
.thin {
  font-weight: 200;
}

.dialog {
  width: 45vw;
}

.spacer {
  flex-grow: 1;
}

.hidden {
  visibility: hidden;
}

.circle-mask {
  height: 140px;
  width: 140px;
  object-fit: cover;
  -webkit-mask-size: 140px;
  -webkit-mask-image: url('/assets/images/circle_mask.png');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-image: url('/assets/images/circle_mask.png');
  mask-size: 140px;
  mask-repeat: no-repeat;
  mask-position: center;

  &.circle-mask-mini {
    width: 60px;
    height: 60px;
    object-fit: cover;
    -webkit-mask-size: 60px;
    mask-size: 60px;
  }

  &.circle-mask-large {
    width: 140px;
    -webkit-mask-size: 140px;
    mask-size: 140px;
  }
}

@media (max-width: 880px){
  .main-title {
    display: none;
  }
}

@import "src/theme/components/mat-form-field.scss";
@import "src/theme/components/mat-navbar.scss";
@import "src/theme/components/mat-button.scss";
@import "src/theme/components/mat-table.scss";
@import "src/theme/components/mat-tabs.scss";
@import "src/theme/components/mat-dialog.scss";
@import "src/theme/components/mat-snackbar.scss";
