@use '@angular/material' as mat;
@import "./theme/palettes.scss";
@import "./theme/explorer-theme.scss";


/* You can add global styles to this file, and also import other style files */
html, body {
  margin:0px;
  height: 100%;
  --login-col-height: 50vh
}

.background {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-image: url("/assets/images/background-image.jpg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.06;
}


.container {
  width: 100vw;
  height: 100vh;
}

.main {
  height: 100vh;
  width: 90vw;
  padding-left: 3vw;
  padding-right: 3vw;
}



.left-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-block-end: 50px;
  padding-inline-start: 3vw;
}

.right-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-block-end: 50px;
  padding-inline-start: 3vw;
}

h1.hero {
  text-transform: uppercase;
  font-size: 4em;
}

h1 h2 {
  font-size: 2rem;
}

.two-col {
  display:flex;
  flex-direction: row;
}

.grid-col-span-2 {
  grid-column: span 2
}

.two-col-grid {
  display: grid;
  grid-template-columns: minmax(120px, 150px) 1fr;
  align-items: center;
}

div.fw {
  width: 100vw;
}

.link {
  cursor: pointer;
  color: mat.get-color-from-palette($explorer-theme-accent, 100);
}

a {
  font-style: normal;
  text-decoration: none;
  color: mat.get-color-from-palette($explorer-theme-accent, 100);
}
a:hover {
  color: mat.get-color-from-palette($explorer-theme-accent,  900);
}
a:focus,
a:focus-visible {
  margin-inline-start: 5px;
}

em {
  font-size: 0.8rem;
}

.two-columns {
  columns: 2;
}

.q2-tooltip{
  white-space: pre-line;
}

.hide {
  display: none !important;
}

.entity-page {
  display: flex;
  flex-direction: column;
  gap: 36px;
  .info{
    display: flex;
    flex-direction: row;
    column-gap: 30px;

    .picture {
      flex: 0 1 140px;
    }
    .name {
      flex: 0.5 0.8 200px;
    }
    .information {
      flex: 0 0 250px;
    }
    .identifiers {
      flex-grow: 1;
      flex-shrink: 1;
      .identifiers-list {
        .identifier {
          position: relative;
        }
      }
    }
  }
  .lists {
    .relationships {
      display: grid;
      grid-template-columns: 180px 1fr;
      margin: 30px 60px;
      .title {
        width: 200px;
        flex: 0 0 200px;
        font-weight: 700;
      }
      .grid {
        flex-grow: 1;
        display: grid;
        grid-row-gap: 23px;
        grid-column-gap: 23px;
      }
      .grid-four-col {
        grid-template-columns: repeat(4, 1fr);
      }
      .grid-two-col {
        grid-template-columns: repeat(2, 200px auto);
      }
      hr {
        width: 100%;
        margin-top: 33px;
        color: mat.get-color-from-palette($explorer-theme-primary, 50);
      }
    }
  }
}

.entity-page {

  div {
    line-height: 1.3em;
  }
  .exports {
    flex: 0 0 180px;
  }
  .name-block {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    h1 {
      font-weight: 100;
    }
    .name {
      padding-left: 5px;
      h1 {
        font-weight: 600;
      }
    }
    .export {
      margin-top: -10px;
      font-size: 1.8em;
    }
  }
  .info {
    .identifiers {
      .identifiers-list {
        .identifier::before {
          content: "";
          position: absolute;
          top: 3px;
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
        }
        .identifier.isni::before,
        .identifier.mergedIsni::before{
          background-color: rgb(142,35,37);
        }
        .identifier.ipi::before{
          background-color: rgb(66,43,122);
        }
        .identifier.ipn::before{
          background-color: rgb(0,128,49);
        }
        .identifier.discogsId::before{
          background-color: rgb(0,124,113);
        }
        .identifier.musicbrainzId::before{
          background-color: rgb(126,71,35);
        }
        .identifier.deezerId::before{
          background-color: rgb(209, 173, 17);
        }
        .identifier.appleId::before{
          background-color: rgb(96,121,24);
        }
        .identifier.spotifyId::before{
          background-color: rgb(134,32,118);
        }
        .identifier.wikidataId::before{
          background-color: rgb(88,88,88);
        }
        .identifier.amazonId::before{
          background-color: rgb(191, 32, 67);
        }
        .identifier.luminateId::before{
          background-color: rgb(74, 6, 130);
        }
        .identifier.gracenoteId::before{
          background-color: rgb(4, 91, 43);
        }
        .identifier.tmsId::before{
          background-color: rgb(246, 232, 70);
        }
      }
    }
  }
  .lists {
    position: relative; //necessary for the export buttons position absolute
    .relationships {
      .grid {
        flex-grow: 1;
        display: grid;
      }
      .gird-four-col {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 23px;
      }
    }
    .export {
      position: absolute;
      top: 13px;
      right: 0px;
      .mat-icon-button {
        font-size: 2.0em;
        margin-top: -10px;
      }
    }
  }
}

.login {
  &.container {
    display: grid;
    grid-template-columns: 35vw 1fr;
  }

  content::before {
    visibility:hidden !important;
  }

  h1.hero {
    text-transform: uppercase;
    font-size: 3em;
  }
  .description {
    line-height: normal;
  }

  div.background {
    position:absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-image: url("/assets/images/quansic_explorer.png");
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 80em;
    background-position-x: -500px;
    opacity: 0.2;
  }

  .mat-form-field {
    width: 500px;
  }

  label {
    margin-right: 20px;
    font-size: 1.5em;
  }

  p {
    font-size: 1.2em;
  }

  .loginForm {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 2em;
    margin-block: 3em;
  }
}

.mat-checkbox.change-pending {
  .mat-checkbox-label {
    color: orange;
  }
  .mat-checkbox-frame {
    border-color: orange;
  }
}

.mat-list-item.change-pending {
  border: 1px dashed orange;
}

span.change-pending {
  border-bottom: 1px dashed orange
}

.change-events {
  // min-width: 70vw !important;
  margin-top: 80px;

  .mat-bottom-sheet-container {
    width: 80vw;
  }
}

.changelog-dialog {
  width: 70vw
}

img.canada-flag {
  width: 30px;
}

button.mat-raised-button.mat-accent:hover {
  color: black;
}
.mat-form-field.mat-form-field-appearance-fill.mat-focused {
  .mat-form-field-label {
    color: mat.get-color-from-palette($explorer-theme-primary, 'A400-contrast');
  }
}

