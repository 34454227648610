button.large {
  transform: scale(1.5);
}
button.transparent {
  background-color: transparent !important;
}

button.mat-raised-button.mat-accent:hover {
  background-color: mat.get-color-from-palette($explorer-theme-accent,  900);
}

button.mat-raised-button:focus,
button.mat-raised-button:focus-visible {
  outline-color: mat.get-color-from-palette($explorer-theme-accent,  900);
  outline-offset: 3px;
  outline-style: solid;
}
