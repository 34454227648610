@use '@angular/material' as mat;


.mat-tab-label-container {
  flex: 0 0 auto ! important;
}

.mat-tab-label {
  opacity: 1 !important;
  font-weight: 600;
}
.mat-tab-label .mat-tab-label-content{
  align-items: baseline !important;
}

.mat-tab-label-active {
  position: relative;
}
.mat-tab-label-active::after{
  content: '·';
  position: absolute;
  left: 50%;
  top: 0px;
  color: mat.get-color-from-palette($explorer-theme-primary, '500-contrast');
  font-size: 1.8em;
}

.mat-tab-label .data-count {
  font-weight: 200;
  font-size: 0.7em;
  padding-left: 5px;
}


.mat-tab-body-active {
  padding-top: 30px;
}
