@use '@angular/material' as mat;


.mat-table {
  background: transparent;
}
.mat-paginator {
  background: transparent;
}
.mat-row, .mat-header-row, .mat-footer-row {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
