@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/Roboto Mono/RobotoMono-ExtraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/Roboto Mono/RobotoMono-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/montserrat-extralight-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/montserrat-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/montserrat-medium-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/montserrat-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/montserrat-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
